import {BaseRequest} from "@/request/BaseRequest";

class Appointment extends BaseRequest {

    public requestPath: string = '/after_sale/appointment';

    /**
     * 处理
     * @param data
     */
    deal(data: {
        id: string,
        realEmployeeId: string,
        serviceDesc: string
    }): any {
        return this.post(`${this.requestPath}/deal`, data);
    }

}

const c = new Appointment();
export {c as Appointment};