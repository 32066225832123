
































import {Vue, Component} from "vue-property-decorator";
import {Appointment} from "@/request/after-sale/Appointment";
import {StoreEmployee} from "@/request/merchant/StoreEmployee";

@Component({
    name: 'after_sale_appointment'
})
export default class after_sale_appointment extends Vue {
    modal: any = Appointment;
    dealModal: any = {
        show: false,
        id: '',
        employeeList: [],
        realEmployeeId: '',
        serviceDesc: ''
    };
    search: Array<any> = [
        {
            type: 'Input',
            label: '客户电话',
            key: 'userPhone',
            width: '140px',
            clearable: true,
            placeholder: '请填写电话'
        },
        {
            type: 'Select',
            label: '状态',
            key: 'status',
            clearable: true,
            width: '120px',
            items: [
                {
                    label: '预约中',
                    value: 0
                },
                {
                    label: '已服务',
                    value: 1
                },
                {
                    label: '超时',
                    value: 2
                },
                {
                    label: '取消',
                    value: 3
                }
            ]
        },
        {
            type: 'DatePicker',
            pickerType: 'daterange',
            format: 'yyyy-MM-dd',
            label: '下单时间',
            width: '210px',
            placeholder: '请选择注册时间'
        }
    ];
    columns: Array<any> = [
        {
            title: '订单号',
            key: 'orderId',
            minWidth: 140
        },
        {
            title: '昵称',
            key: 'userNickName',
            minWidth: 150
        },
        {
            title: '状态',
            key: 'status',
            minWidth: 120,
            render: (h: any, p: any) => h('p', p.row.status === 0 ? '预约中' : p.row.status === 1 ? '已服务' : p.row.status === 2 ? '超时' : p.row.status === 3 ? '取消' : '-')
        },
        {
            title: '电话',
            key: 'userPhone',
            minWidth: 150
        },
        {
            title: '预约门店',
            key: 'storeName',
            minWidth: 140
        },
        {
            title: '预约员工',
            key: 'appointEmployeeName',
            minWidth: 140
        },
        {
            title: '预约时间',
            key: 'appointStartTime',
            minWidth: 350,
            render: (h: any, p: any) => h('p', `${p.row.appointStartTime} - ${p.row.appointEndTime}`)
        },
        {
            title: '服务员工',
            key: 'realEmployeeName',
            minWidth: 120
        },
        {
            title: '员工服务备注',
            key: 'serviceDesc',
            minWidth: 140,
            tooltip: true
        },
        {
            title: '创建时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
    deal(row: any) {
        let self: any = this;
        this.dealModal.id = row.id;
        this.$Message.loading('请稍后');
        StoreEmployee.getAll().then((body: any) => {
            if (body.code === 0) {
                this.dealModal.show = true;
                this.$Message.destroy();
                this.dealModal.employeeList = body.data;
            }
        })
    }
    submit() {
        let self: any = this;
        this.$Message.loading('请稍后');
        Appointment.deal({
            id: this.dealModal.id,
            realEmployeeId: this.dealModal.realEmployeeId,
            serviceDesc: this.dealModal.serviceDesc
        }).then((body: any) => {
            if (body.code === 0) {
                this.$Message.destroy();
                this.$Message.info('处理成功');
                self.$refs['el-table'].flush();
            }
        })
    }
}
